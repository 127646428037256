<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-width-1-1 uk-flex uk-flex-column-sm uk-flex-column-md uk-flex-row uk-flex-between uk-flex-middle"
    >
      <div class="uk-width-1-1 uk-width-auto@l">
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
      <div class="uk-width-1-1 uk-width-auto@l uk-margin-small-top uk-margin-remove-top-lg uk-margin-remove-top-xl">
        <button
          v-if="isCanAccessUser(`add`, `ROLE`)"
          class="uk-button green uk-width-1-1"
          @click.prevent="$router.push({ name: 'RoleCreate' })"
        >
          <img
            :src="`${images}/icon/ic_plus_white.svg`"
            class="uk-margin-small-right"
            alt="ic_plus"
            loading="lazy"
          >
          Add Role
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <header-role
        :images="images"
        :list-assign-at="listAssignAt"
        :filter-table-role="filterTableRole"
        :reset-filter-role="resetFilterRole"
        :meta-filter="metaFilter"
      />
      <content-role
        :images="images"
        :list-role="listRole"
      />
      <pagination
        :total-data="totalData"
        :change-limit="changeLimit"
        :change-page="changePage"
        :current-page="currentPage"
        :current-limit="currentLimit"
      />
    </div>
    <modal-add-edit-discard
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import HeaderRole from './HeaderRole'
import ContentRole from './ContentRole'
import Pagination from '@/components/globals/Pagination'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderRole,
    ContentRole,
    Pagination,
    ModalAddEditDiscard
  },
  props: {
    dataRole: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10,
      loadingType: null,
      filterRole: {}
    }
  },
  computed: {
    ...mapGetters({
      listAssignAt: 'masterData/list_master_data',
      listRole: 'role/list_role',
      metaFilter: 'role/meta_filter',
      getDataModalAddEdit: 'role/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'role/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    listRole(data) {
      this.totalData = data.meta.total
    }
  },
  async mounted() {
    this.filterRole = this.dataRole?.find(item => item.name.toUpperCase() == 'ROLE')
    await this.actionGetListMasterData({data_type: 'assigned_at_types'})
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetListMasterData: 'masterData/getListMasterData',
      actionGetListRole: 'role/getListRole',
      deleteRole: 'role/deleteRole'
    }),
    ...mapMutations({
      setMetaFilter: 'role/SET_META_FILTER',
      setToggleModalAddEditDiscard: 'role/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async loadPage() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      if(this.metaFilter == undefined) {
        await this.actionGetListRole({ page: this.currentPage, limit: this.currentLimit})
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      } else {
        await this.actionGetListRole({ page: this.currentPage, limit: this.currentLimit})
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      }
    },
    async filterTableRole() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListRole({...this.metaFilter, page: 1, limit: 10})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async resetFilterRole() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = 1
      this.currentLimit = 10
      await this.actionGetListRole({ page: this.currentPage, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changePage(page) {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = parseInt(page)
      await this.actionGetListRole({...this.metaFilter, page: page, limit: 10})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListRole({...this.metaFilter, page: 1, limit: event.target.value})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    handleModalDelete(){
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        loadingTable: false,
        loadingPopUp: true,
        id: this.getToggleModalAddEditDiscard.id
      })
      this.deleteRole(this.getToggleModalAddEditDiscard.id).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: true,
              loadingPopUp: false
            })
            notificationSuccess('Delete Role Successful')
          }, 500)
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            this.loadPage()
          }, 1000)
        } else {
          this.setToggleModalAddEditDiscard({})
        }
      })
    }
  }
  
}
</script>
