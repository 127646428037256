<template>
  <div>
    <table-content-role
      :images="images"
      :list-role="listRole"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import TableContentRole from '@/components/globals/table/role/index'

export default {
  components: {
    TableContentRole
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listRole: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'role/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'role/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
