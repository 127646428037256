<template>
  <div
    class="uk-form-stacked"
  >
    <div
      class="uk-child-width-1-1@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-4@l">
        <label class="uk-form-label">Role ID or Name</label>
        <div class="uk-form-controls">
          <input
            v-model="search"
            name="search"
            class="uk-input"
            type="text"
            placeholder="Search Role ID or Name"
            autocomplete="off"
          >
        </div>
      </div>

      <div class="uk-width-1-1@s uk-width-1-6@l">
        <div class="uk-form-controls uk-position-relative withlabel">
          <label class="uk-form-label">Assigned at</label>
          <select
            v-model="assigned_at"
            name="assigned_at"
            class="uk-width-expand custom-select"
            placeholder="Assigned at"
          >
            <option
              value=""
              hidden
            >
              All
            </option>
            <option
              v-for="(item, key) in listAssignAt"
              :key="key"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <img
            :src="`${images}/icon/ic_chevron_down.svg`"
            alt="ic_chevron"
          >
        </div>
      </div>

      <div class="uk-width-1-1@s uk-width-1-6@l">
        <div class="uk-form-controls uk-position-relative withlabel">
          <label class="uk-form-label">Status</label>
          <select
            v-model="status"
            name="status"
            class="uk-width-expand custom-select"
            placeholder="Status"
          >
            <option
              v-for="(item, key) in statusFilter"
              :key="key"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
          <img
            :src="`${images}/icon/ic_chevron_down.svg`"
            alt="ic_chevron"
          >
        </div>
      </div>
      
      <div class="uk-width-1-1@s uk-width-1-4@l uk-flex uk-flex-bottom">
        <button
          class="uk-button uk-button-primary uk-margin-small-right uk-padding-small"
          @click.prevent="handleSearch"
        >
          <img
            :src="`${images}/icon/ic_search_white.svg`"
            class="uk-margin-small-right"
            alt=""
            loading="lazy"
          >
          Search
        </button>
        <button
          class="uk-button uk-button-default uk-padding-remove"
          @click.prevent="handleReset"
        >
          Reset Filter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { STATUS_FILTER } from '@/utils/constant'
export default {
  props: {
    images: {
      required: true,
      type: String
    },
    listAssignAt: {
      required: true,
      type: Array
    },
    filterTableRole: {
      required: true,
      type: Function
    },
    resetFilterRole: {
      required: true,
      type: Function
    },
    metaFilter: {
      required: false,
      default: null,
      type: Object
    }
  },
  data() {
    return {
      search: '',
      assigned_at: '',
      status: null,
      statusFilter: STATUS_FILTER
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'role/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setMetaFilter: 'role/SET_META_FILTER'
    }),
    handleSearch() {
      this.setMetaFilter({
        ...this.metaFilter,
        search: this.search || null,
        assigned_at: this.assigned_at,
        status: this.status
      })
      this.filterTableRole()
    },
    handleReset() {
      this.search = ''
      this.assigned_at = ''
      this.status = null
      this.resetFilterRole()
    }
  }
}
</script>

<style scoped>
  .multiselect__placeholder{
    color: #000000 !important;
  }
</style>
