<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="role-table"
      >
        <header-table-role />
        <template v-if="getToggleModalAddEditDiscard.loadingTable">
          <loading-table :colspan="5" />
        </template>
        <template v-else-if="listRole.data && listRole.data.length > 0">
          <tbody>
            <tr
              v-for="(content, index) in listRole.data"
              :key="index"
            >
              <td>{{ content.role_code || '-' }}</td>
              <td>{{ content.name || '-' }}</td>
              <td>{{ content.assigned_at || '-' }}</td>
              <td>
                <div v-if="content.status === 1">
                  <label-status
                    status="active"
                    label="Active"
                    table="true"
                  />
                </div>
                <div v-else-if="content.status === 0">
                  <label-status
                    status="inactive"
                    label="Inactive"
                    table="true"
                  />
                </div>
              </td>
              <td class="action">
                <a
                  uk-tooltip="Delete"
                  :class="isCanAccessUser('delete', 'ROLE') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('delete', 'ROLE') ? 'ic_delete_table.svg' : 'ic_delete_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click="showDeleteConfirmModal(content.id)"
                  >
                </a>
                <a
                  uk-tooltip="Edit"
                  :class="isCanAccessUser('edit', 'ROLE') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('edit', 'ROLE') ? 'ic_edit_table.svg' : 'ic_edit_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click.prevent="$router.push({ name: 'RoleEdit', params:{ id: content.id }})"
                  >
                </a>
                <a
                  uk-tooltip="View"
                  :class="isCanAccessUser('view', 'ROLE') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('view', 'ROLE') ? 'ic_eye_table.svg' : 'ic_eye_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click.prevent="$router.push({ name: 'RoleDetail', params:{ id: content.id }})"
                  >
                </a>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="5"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderTableRole from './HeaderTableRole'
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import LabelStatus from '@/components/globals/LabelStatus'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderTableRole,
    EmptyTable,
    LoadingTable,
    LabelStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listRole: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    showDeleteConfirmModal(id) {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id
      })
    }
  }
}
</script>

<style scoped>
.disabled {
  cursor: no-drop;
}
</style>
